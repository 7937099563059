.rowMargin {
    margin-left: 0;
    margin-right: 0;
}
.txtAlgnRight {
    text-align: right;
}
.commonDpFlex{
    display: flex;
}
.commonFlexWrap{
    flex-wrap: wrap;
}
.progInfoContainer {
    padding: 0 55px;
    margin-top: 80px;
    position: relative;
}
.sideBarWrap + div {
    z-index: 2 !important;
}
.firsttime_changepassword_text{
    margin-top: 2vw;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.40;
    letter-spacing: normal;
    color: #000000;
    text-align:center;
}
.firsttime_changepassword_subtext{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.40;
    letter-spacing: normal;
    color: #000000;
    text-align:center;
    margin-bottom: 10px;
}
.commonWrap {
    padding-top: 18px;
}
.commonWrap h2{
    margin: 0;
}
.Back_page {
    width: 87px;
    /* height: 24px; */
    margin: 0;
    padding: 32px 0 0 0;
    font-family: Tungsten;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.44px;
    text-align: center;
    color: #543379;
    cursor: pointer;
    text-align: left;
}
.progInfoSection {
    padding: 20px 34px;
    background: #ffffff;
}
.progInfoTitle {
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #543379;
    text-transform: uppercase;
    margin: 0;
}
.tablebackgroundouter{
    background-color: white;
    /* border: 1px solid red; */
    /* height: 300px; */
    /* padding: 10px 30px; */
    /* overflow: auto; */
    min-height: 300px;    
}
.msgWrapper {
    border-radius: 6px;
    background-color: #304d63;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 10px;
}
.msgWrapper .msgVertCenter {
    min-height: 40px;
    line-height: 35px;
}
.msgWrapper h6 {
    font-family: Roboto;
    color: #f2f2f2;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}
.searchInutBox {
    width: 100% !important;
}
.pwdDivEyeIcon {
    right: 65px;
    top: 13px;
}
/*--=== Member Information 
=============================================--*/
.frontViewWrapper {
    position: relative;
}
.healthCardWrapper {
    padding: 40px 35px;
    background: #eeeeee;
}
.programInfoWrapper {
    padding: 40px;
}
.remainingAmt {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}
.memberDetailsWrapper{
    margin: 10px 0 0 0;
}
.memberitemRow {
    margin-bottom: 15px;
}
.memberitemRow h5 {
    font-size: 10px;
    font-weight: 700;
    color: #000000;
    margin: 0;
    font-family: Roboto;
    line-height: 14px;
}
.memberitemRow h6 {
    font-size: 10px;
    font-weight: 500;
    color: #8850ab;
    margin: 0;
    font-family: Roboto;
    line-height: 14px;
}
.memberdependentItemRow h5 {
    font-size: 8px;
    font-weight: 500;
    color: #8c1342;
    margin: 0;
    font-family: Roboto;
    line-height: 12px;
}
.memberdependentItemRow h6 {
    font-size: 8px;
    font-weight: 700;
    color: #8850ab;
    margin: 0;
    font-family: Roboto;
    line-height: 12px;
}
.dependentContainer h4{
    font-size: 8px;
    font-weight: 700;
    color: #000000;
    margin: 0;
    font-family: Roboto;
    line-height: 12px;
}
.dependentContainer .memberdependentItemRow h5{
    font-weight: 500;
    color: #8c1342;
}
.dependentContainer .memberdependentItemRow h6{
    font-weight: 500;
    color: #8850ab;
}
.dependentContainer > .memberdependentItemRow:first-child{
    display: none;
}
.userDetailRight .memberdependentItemRow h5{
    width: 90px;
    color: #612b64;
}
.userDetailRight .memberdependentItemRow h6{
    color: #4f1252;
    font-weight: 700;
}
.cardBottomContent {
    position: absolute;
    bottom: 5px;
}
.cardBottomContent .planFooter{
    width: 100%;
    justify-content: center;
    font-size: 7px;
    text-align: center;
    font-family: Roboto;
    font-weight: normal;
    color: #000000;
}
.userDetailLeft {
    max-height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
}
.moreUserDataWrapper {
    margin-top: 8px;
    max-height: 50px;
    overflow-y: auto;
    overflow-x: hidden;
}
.zoomButton {
    background: #41b5c2;
    z-index: 2;
}
.The-fellowship-incurs-additional-fees-related-to-p {
    font-family: Roboto;
    font-size: 14px;
}
.forgorPwdForm {
    display: flex;
    flex-wrap: wrap;
}
.pwdDiv {
    position: relative;
}
.smallCardBox {
    margin-top: 30px;
    margin-bottom: 30px;
}
.providerDetailBg {
    background-color: rgba(234, 232, 219, 0.4);
    padding: 18px;
    height: 40vh;
}
.providerDetailBg h4 {
    font-family: Roboto;
    font-size: 14px;
    color: #4f4f4f;
    line-height: 1.29;
    margin: 0 0 5px 0;
}
.providerDetailBg h2 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    color: #33ab8f;
    line-height: 1.2;
    margin: 0 0 20px 0;
}
.modalTitle {
    color: #5f2161;
    font-size: 16px;
    font-weight: 500;
    padding-top: 2px;
}
.notificationTray {
    overflow: auto;
    height:19vw;
}
/*--=== AIKB Header CSS 
=============================================--*/
.header-container {
    width: 100%;
    height: 60px;
    background-color: #533278;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-container .logo {
	width: 158px;
    margin-top: 0;
}
.header-container .caption {
    width: 50%;
	margin:0 auto;
    font-family: Tungsten;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.44px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.header-container .icons-header{
	width: 13%;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.44px;
    color: #fff;
    margin-right: 14px;
}
/*--=== Footer 
=============================================--*/
.footerContainer {
    /* position: absolute; */
    width: 100%;
    bottom: 10px;
}
.footerContainer .webfooter {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}


/*--=== Media Query 
=============================================--*/

@media only screen and (max-width: 460px) {
    .Back_page{
      display: none !important;
    }
    .programInfoWrapper {
        padding: 10px;
    }
    .progInfoContainer {
        padding: 0;
        margin-top: 60px;
    }
    .notification_category_label_details {
        font-size: 12px;
        margin: 0 0 8px 0;
    }
    .progInfoFooter {
        padding: 0px 10px 20px 10px !important;
    }
    .txtAlgnRight {
        text-align: left;
    }
    .frontCardModal .fview {
        height: 480px;
    }
    .backCardModal .fview {
        height: 480px;
    }
    .mobile_full_size + div>div>div:first-child {
        width: 90% !important;
    }
    .pwdDivEyeIcon {
        right: 20px;
    }
    .pwdDiv{
        width: 100%;
    }
    .msgWrapper{
        width: 90%;
    }
    .ediNumber {
        top:55px
    }
    .backCardModal .ediNumber{
        top: 90px;
    }
    .notificationTray {
        height:38vw;
    }
    .documents_infomiddiv {
        height: 45vw;
    }
    .smallCardBox {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .modalTitle {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .header_name {
        font-size: 14px;
        margin-left: 10px;
    }    
    .progInfoTitle {
        font-size: 28px;
    }
    .appsicon_cursor button {
        padding: 5px;
    }
    .h_new {
        padding: 0 10px;
    }

}