@font-face {
  font-family: Tungsten;
  src: url('../../Assets/Tungsten/font-face/561f38b1f4570de0fb8a39d691ab058c.ttf');
}


/* .progInfoContainer {
    padding: 0 !important;
    margin-top: 80px;
    position: relative;
  } */
  
/* .Back_page {
    width: 87px;
    height: 24px;
    margin: 3px 0 0 5px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.44px;
    text-align: center;
    color: #543379;
    cursor: pointer;
} */
/* .commonWrap {
    padding-top: 26px;
  }
  .commonWrap h2{
    margin: 0;
  } */
  .inputStyle{
    margin-left: 30px !important;
    max-width: 204px;
    width: 100%;
  }
  /* .progInfoSection {
    padding: 20px;
    background: #ffffff;
  } */
  /* .progInfoTitle {
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #543379;
    text-transform: uppercase;
  } */
 /* .tablebackgroundouter{
      background-color: white;
      border: 1px solid red;
      height: 300px;
      padding: 10px 30px;
      overflow: auto;
      
  } */
.tablebackgroundouterPaymentWallet .MuiCollapse-wrapperInner td, .tablebackgroundouter .MuiCollapse-wrapperInner th {
    border-bottom: none !important;
    /* padding: 5px 16px 0px 16px !important; */
  }
  .tablebackgroundouterPaymentWallet .viewCardBtn {
    position: relative;
    margin-top: -20px;
  }
  .progInfoFooter {
    background: #F7F7F7;
    border-top: 1px solid #bdbdbd;
    padding: 20px;
  }
  .blueActionBtn {
    font-family: Tungsten !important;
    border-radius: 20px;
    border: 1px solid;
  
    border: 1px solid;
    background-color: #41b5c2;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: #ffffff;
    padding: 5px 30px;
  }
  .footerText {
    padding: 9px 15px 0 172px;
  }
  .footerText p{
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #333333;
    padding: 2px 0;
  }
  .footerText p:first-child{
    font-weight: bold;
  }
  .Rectangle-271 {
    /* width: 82vw; */
    height: auto;
    background-color: #fcfcfc;
  }
  .line{
        background: #F7F7F7;
        border-top: 1px solid #bdbdbd;
        padding: -2px;
    }
  .client {    
    /* height: 65px; */
    font-family: 'Anton', sans-serif !important;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 32px;
  }
  .client h1{ 
    font-size: 60px;
    display: inline;
  }
  .client h2{ 
    font-size: 45px;
    display: inline;
  }
  .client .p {
    font-size: 80px;
    line-height: 0.9;
  }
  .borderRight {
    border-right: 1px solid #dfdfdf;
  }
  .infoHeading {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin: 0 0 20px 0;
  }
  .progessBarWrapper {
    padding-right: 20px;
    margin-bottom: 50px;
  }
  .barTitle {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-align: left;
    color: #454d58;
    margin-bottom: 15px;
  }
  .amtWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  .metAmt {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #eb5757;
  }
  .metAmtYellow {
    color: #fec465;
  }
  .progressContainer {
    height: 16px;
    width: 100%;
    background: rgba(235, 87, 87, 0.5);
    border-radius: 50px;
  }
  .progressIndicator {
    background-color: #eb5757;
    /*width: 60%;*/
    height: 100%;
    border-radius: 50px;
    text-align: right;
  }
  
  .progressContainerYellow {
    height: 16px;
    width: 100%;
    background: rgba(254, 196, 101, 0.5);
    border-radius: 50px;
  }
  .progressIndicatorYellow {
    background-color: #fec465;
    /*width: 60%;*/
    height: 100%;
    border-radius: 50px;
    text-align: right;
  }
  .leftData{
    max-height:400px ;
    overflow-y: auto;
    overflow-x: hidden;
    /* padding-top: 106px; */
  }
  .label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.12px;
    text-align: left;
    color: #454d58;
    margin: 0 0 2px 0;
  }
  .number {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin: 0 0 2px 0;
  }

  /* IDCARD Css */
  .fview{
    /* display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* border: 1px solid red;  */
    /* margin: 0vw 2vw; */    
    /* margin-top:-54px; */
    /* height:12vw;  */
    height:210px;
    border-radius: 8px;   
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    /* margin-bottom: 32px; */
    position: relative;
  }
  .fv_background_image{
    /* background-image: url('../../Images//Drawer/group-51.png');
    background-repeat: no-repeat;
    width: 100%; */
    background-image: url('./Image/background.svg');
    background-repeat: no-repeat;
    background-size: contain;
    /* background-size: 51% 100%; */
    /* width: 290.8px; */
    width: 100%;
    max-height: 210px;
    height: 100%;
    border-radius: 8px;
    
}
.fv_background_image_blue{ 
  background-image: url('./Image/card_bg_02.png') !important;
  
}
.fv_top_image_container{
  /* border:1px solid green; */
  display: flex;
  height: 11vw;
  width: 21.5vw;
  justify-content: space-between;
  /* padding: 1vh 2vw; */
  padding: 1vh 0vh;
  padding-right: 2vw;
}
.fv_left_logo{
  /* width: 8vw;
  height: 6vh; */
  /* width: 7vw;
  height: auto;
  object-fit: contain; */
  /* border:1px solid red; */
  height: 42px;
}
.fv_right_logo{
  /* width: 8vw;
  height: 6vh; */
  /* width: 5vw;
  float: right;
  object-fit: contain;
  height: auto; */
  height: 42px;
}
.fv_card{
  /* border: 1px solid red; */
  width: 100%;
  /* height: 300px; */
  display: flex;
  justify-content: space-between;
  height: auto;
  /* padding: 1vh 2vw; */
}
.fv_left_container{
  width: 14%;
  padding:0vh 1vw;
  position: absolute;
  margin-top: -8vw;
}

.fv_list_view{
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.fv_list_adultname{
    font-size: 0.5vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#8c1342;
    width: 5vw;
}
.fv_list_username{
  font-size: 0.6vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8850ab;
  margin-left: -1.5vw;
  flex: 1;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  margin-top: 0px;
}
.fv_right_container{
  /* border: 1px solid blue; */
  /* flex: 1; */
  margin-top: -8vw;

  width: 8.6%;
  position:absolute;
  padding: 0vh 0vw;
  }
  .fv_joined_text{
    /* width: 63.1px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    text-align: left;
    position: relative;
    font-size:0.7vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.fv_right_table1{
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
}
.fv_left_table{
  /* border: 1px solid black; */
  width: 55%;
  margin-left: 0vw;
}
.fv_right_table{
  margin-left: 0px;
  /* border: 1px solid blue; */
  width: 45%;
}



.fv_right_new_table{
  /* border: 1px solid greenyellow; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.fv_new_table_left_text{
  /* border: 1px solid greenyellow; */
  width: 55%;

    /* width: 46.9px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size: 1.6vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #612b64;
}
.fv_new_table_right_text{
  /* border: 1px solid greenyellow; */
  width: 45%;

   /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size:1.6vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #420045;
  word-wrap: break-word;
}
.fv_space_2table{
  margin-top: 0.2vw;
}
.fv_table_left_text{
  width: 4rem;
  /* border:1px solid red; */
  /* width: 46.9px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size: 0.7vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color:#541957;
  margin-bottom: 4px;
}
.fv_table_right_text{
  /* width: 5rem;
  border:1px solid red; */
  /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size:0.7vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f1252;
  margin-bottom: 4px;
}
.fv_table_middle_text{
  /* border: 1px solid green; */
    /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size:0.7vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f1252;
  margin: 0.5vw 0px;
}
.fv_center_details_text{
  /* padding: 1.5vw 3vw; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 17vw;
/* margin-top: 10px; */
text-align: center;
margin-left: 34px;
font-size: 0.2vw;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #000000;
}
.cardheaderLogo {
  max-height: 52px;
  padding: 10px 0 0 0;
}
.cardheaderLogo .rightlogo {
  text-align: right;
}
/*--- For FrontView Modal ---- */
.frontCardModal .modal-dialog {
  max-width: 700px;
}
.frontCardModal .fview {
  height: 420px;
}
.frontCardModal .fv_background_image {
  max-height: 420px;
}
.frontCardModal .cardheaderLogo {
  max-height: 70px;
}
.frontCardModal .cardheaderLogo .fv_left_logo {
  height: 60px;
}
.frontCardModal .cardheaderLogo .fv_right_logo {
  height: 60px;
  padding-right: 30px;
}
.frontCardModal .memberDetailsWrapper {
  padding: 10px 15px;
}
.frontCardModal .memberitemRow h5 {
  font-size: 16px;
  line-height: 22px;
}
.frontCardModal .dependentContainer h4 {
  font-size: 16px;
  line-height: 22px;
}
.frontCardModal .memberitemRow h6 {
  font-size: 14px;
  line-height: 20px;
}
.frontCardModal .memberdependentItemRow h5, .frontCardModal .memberdependentItemRow h6 {
  font-size: 14px;
  line-height: 24px;
}
.frontCardModal .cardBottomContent .planFooter {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 15px;
}
.frontCardModal .cardBottomContent {
  width: 95%;
}
.frontCardModal .userDetailLeft {
  max-height: 100%;
}
.frontCardModal .moreUserDataWrapper {
  max-height: 145px;
  margin-top: 15px;
}
/*--- For BackView Modal ---- */
.backCardModal .modal-dialog {
  max-width: 700px;
}
.backCardModal .fview {
  height: 420px;
}
.backCardModal .thankYouImgDiv {
  text-align: left;
}
.backCardModal .thankYouImgDiv img{
  width: 50%;
}
.backCardModal .backTextHeight {
  max-height: 225px;
  overflow: auto;
}
.backCardModal .forPreventBack {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
}
.backCardModal .textBackView {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.backCardModal .italicTxt{
  font-style: italic;
  font-weight: 700;
  margin: 0 auto;
  max-width: 450px;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
}
.backCardModal .backViewBottomNumber {
  font-size: 12px;
  line-height: 16px;
}
.backCardModal .blueTextCommon {
  font-size: 14px !important;
}
.backCardModal .ediNumber {
  margin: 0;
  position: absolute;
  right: 25px;
  top: 105px;
  background-color: #4a0b4d;
  color: #fff;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
}

/* IDCarBack */

.THANK-YOU-FOR {
  width: 100%;
  /* position: absolute;
  padding-bottom: 0px;
  margin-left: 1vw; */
}
.sharing {
  width: 6vw;
  margin-top: -9px;
  position: absolute;
  padding-bottom: 0vw;
  margin-left: 11.5vw;
}
.ForPrevent{
  width: 100%;
  display: flex;
  padding: 0 17px;
  font-size: 36%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 19%;
  font-family: Roboto;
  text-align: left;
  color: #543379;
}
.forPreventBack {
  font-size: 7px;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  color: #543379;
  margin-bottom: 5px;
}
.blueTextCommon {
  color: #543379 !important;
  font-weight: 700 !important;
}
.textBackView {
  font-size: 7px;
  text-align: left;
  font-family: Roboto;
  font-weight: normal;
  color: #000000;
  margin: 0;
}
.backTextHeight {
  max-height: 60px;
  overflow: hidden;
}
.backViewBottomNumber{
  font-size: 7px;
  text-align: right;
  font-family: Roboto;
  font-weight: 700;
  color: #000000;
  margin: 0;
  padding: 5px 0;
}
.bottomNumberPos {
  position: absolute;
  bottom: 0;
}
.textBAck{
  /* width: '19vw'; */
  display: flex;
  padding: 0 17px;
  font-size: 35%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 2.2%;
  font-family: Roboto;
  text-align: left;
  color: #000000;
}
.bottomnumber{
  position: absolute;
  margin-top: -5px;
  width: 56.9px;
  height: 5.9px;
  margin-left: 62%;
  font-family: Roboto;
  font-size: 5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.backViewWrapper{
  position: relative;
}
.ediNumber {
  margin: 0;
  position: absolute;
  right: 15px;
  top: 85px;
  background-color: #4a0b4d;
  color: #fff;
  padding: 0 5px;
  font-size: 8px;
  font-weight: 300;
}
@media only screen and (max-width: 600px) {
    /* .fview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.8vw;
      margin: 0vw 2vw;
      box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
      background-color: white;
      margin-top: -54px;
      height: 50vw;
  } */
  .fv_top_image_container {
    display: flex;
    height: 45vw;
    width: 76.5vw;
    justify-content: space-between;
    padding: 1vh 0vh;
    padding-right: 2vw;
  }
  /* .fv_left_logo {
    width: 25vw;
    height: auto;
    object-fit: contain;
     border: 1px solid red; 
  } */
  /* .fv_right_logo {
    width: 16vw;
    object-fit: contain;
    height: auto;
  } */
  .fv_list_adultname {
    font-size: 2.5vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8c1342;
    width: 25vw;
}
.fv_left_container {
  width: 33%;
  padding: 0vh 1vw;
  position: absolute;
  margin-top: -26vw;
}
.fv_list_username {
  font-size: 2.6vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8850ab;
  margin-left: 0.5vw;
  flex: 1 1;
}
.fv_right_container {
  margin-top: -27vw;
  width: 16.6%;
  position: absolute;
  padding: 0vh 1vw;
}
.fv_left_table {
  /* border: 1px solid black; */
  width: 55%;
  margin-left: 0vw;
}
.fv_table_left_text {
  width: 5rem;
  font-size: 3vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #541957;
  margin-bottom: 4px;
}
.fv_joined_text {
  text-align: left;
  position: relative;
  font-size: 1.9vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.fv_table_right_text {
  font-size: 3vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f1252;
  margin-bottom: 4px;
}
.fv_center_details_text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 67vw;
  text-align: center;
  margin-left: 34px;
  font-size: 1.2vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.client {
  min-height: 65px;
  font-family: 'Anton', sans-serif !important;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
/* .THANK-YOU-FOR {
  width: 82%;
  margin-top: -10px;
  position: absolute;
 
  padding-bottom: 0vw;
  margin-left: 2vw;
} */
.sharing {
  width: 15vw;
  margin-top: -16px;
  position: absolute;
  padding-bottom: 7vw;
  margin-left: 33vw;
}
.ForPrevent {
    margin-top: 45px;
    width: 76vw;
    display: flex;
    font-size: 1.4vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-top: 12vw;
    padding: 0 8px;
    font-family: Roboto;
    text-align: left;
    color: #543379;
}
.textBAck {
  width: '19vw';
  display: flex;
  padding: 0px 8px;
  font-size: 1.5vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 2.2vw;
  font-family: Roboto;
  text-align: left;
  color: #000000;
}
.bottomnumber {
  position: absolute;
  margin-top: 0vw;
  width: 56.9px;
  height: 5.9px;
  margin-left: 50vw;
  font-family: Roboto;
  font-size: 5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.tablebackgroundouter {
  background-color: white;
}
.Rectangle-271 {
  overflow-x: hidden;
  /* width: 98vw; */
  height: auto;
  background-color: #fcfcfc;
}
.leftData {
  /* width: 100vw;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
   padding-top: 106px;  */
   max-height: inherit;
}
.inputStyle{
  margin-left: 0px !important;
}
.lineBreak{
  background: #F7F7F7;
  border-top: 1px solid #bdbdbd;
  margin-bottom: 20px;
  /* padding: -2px;
  width:100vw; */
}
}