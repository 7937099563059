.fviewModal{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red;  */
    border-radius: 0.8vw;
    margin: 0vw 0vw;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-top:0px;
    height:22vw; 

  }
  .fv_background_imageModal{
    background-image: url('../Image/background.svg');
    background-repeat: no-repeat;
    background-size: 51% 100%;
    width: 100%;
    border-radius: 0.8vw;
}
.fv_top_image_containerModal{
  /* border:1px solid green; */
  display: flex;
  height: 21.5vw;
  /* width: 36vw; */
  justify-content: space-between;
  /* padding: 1vh 2vw; */
  padding: 1vh 0vh;
  padding-right: 2vw;
}
.fv_left_logoModal{
  /* width: 8vw;
  height: 6vh; */
  width: 12vw;
  height: auto;
  object-fit: contain;
  /* border:1px solid red; */
}
.fv_right_logoModal{
  /* width: 8vw;
  height: 6vh; */
  float: right;
  width: 8vw;
  object-fit: contain;
  height: auto;
}
.fv_card{
  /* border: 1px solid red; */
  width: 100%;
  /* height: 300px; */
  display: flex;
  justify-content: space-between;
  height: auto;
  /* padding: 1vh 2vw; */
}
.fv_left_containerModal{
  width: 60%;
  padding:0vh 1vw;
  position: absolute;
  margin-top: -15vw;
}

.fv_list_view{
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.fv_list_adultnameModal{
    font-size: 1vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#8c1342;
    width: 10vw;
}
.fv_list_usernameModal{
  font-size: 1vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8850ab;
  margin-left: -3.5vw;
  flex: 1;
}
.img-fluidModal {
  max-width: 100%;
  height: auto;
  margin-top: 0vw;
}
.fv_right_containerModal{
  margin-top: -15vw;
  width: 40%;
  position:absolute;
  padding: 0vh 1vw;
  }
  .fv_joined_textModal{
    /* width: 63.1px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    text-align: left;
    position: relative;
    font-size:1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.fv_right_table1{
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
}
.fv_left_tableModal{
  /* border: 1px solid black; */
  width: 90%;
  margin-left: 0px;
}
.fv_right_table{
  /* border: 1px solid blue; */
  width: 45%;
}



.fv_right_new_table{
  /* border: 1px solid greenyellow; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.fv_new_table_left_textModal{
  /* border: 1px solid greenyellow; */
  width: 55%;

    /* width: 46.9px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size: 1.6vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #612b64;
}
.fv_new_table_right_text{
  /* border: 1px solid greenyellow; */
  width: 45%;

   /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size:1.6vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #420045;
  word-wrap: break-word;
}
.fv_space_2table{
  margin-top: 0.2vw;
}
.fv_table_left_textModal{
    width: 6rem;
    font-size: 1vw;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #541957;
    margin-bottom: 4px;
}
.fv_table_right_textModal{
  /* width: 5rem;
  border:1px solid red; */
  /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size:1vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f1252;
  margin-bottom: 4px;
}
.fv_table_middle_text{
  /* border: 1px solid green; */
    /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size:0.7vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f1252;
  margin: 0.5vw 0px;
}
.fv_center_details_textModal{
     /* padding: 1.5vw 3vw; */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    position: relative;
    /* margin-top: 10px; */
    text-align: center;
    /* margin-left: 25px; */
    font-size: 7px;
    margin-top: -36px;
    font-weight: 500;
    margin-bottom: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000
    
}


.THANK-YOU-FORModal {
    width: 22vw;
    position: absolute;
    padding-bottom: 18vw;
    margin-left: 1rem;
  }
  .sharingModal {
    width: 8vw;
    position: absolute;
    padding-bottom: 18vw;
    margin-left: 310px;
  }
  .ForPreventModal{
    display: flex;
    font-size: 9px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* padding-top: 3vw; */
    padding: 50px 2px 1px 11px;
    font-family: Roboto;
    text-align: left;
    color: #543379;
    width: '19vw';
  }
  .textBAckModal{
    width: '19vw';
    display: flex;
    font-size: 9px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 7px;
    font-family: Roboto;
    text-align: left;
    color: #000000;
  }
  .bottomnumberModal{
    position: absolute;
    margin-top: -1vw;
    width: 56.9px;
    height: 5.9px;
    margin-left: 45%;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  @media only screen and (max-width: 600px) {
  .fviewModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    border-radius: 0.8vw;
    margin: 0vw 0vw;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
    background-color: white;
    margin-top: 0px;
    height: 54vw;
}
.fv_top_image_containerModal {
  /* border: 1px solid green; */
  display: flex;
  height: 50.5vw;
  /* width: 36vw; */
  justify-content: space-between;
  /* padding: 1vh 2vw; */
  padding: 1vh 0vh;
  padding-right: 2vw;
}
.img-fluidModal {
  max-width: 100%;
  height: auto;
  margin-top: 0vw;
}
.fv_left_logoModal {
  width: 27vw;
  height: auto;
  object-fit: contain;
  /* border: 1px solid red; */
}
.fv_right_logoModal {
  width: 16vw;
  object-fit: contain;
  height: auto;
}
.fv_list_adultnameModal {
  font-size: 3vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8c1342;
  width: 41vw;
}
.fv_left_containerModal {
  width: 39%;
  padding: 0vh 2vw;
  position: absolute;
  margin-top: -36vw;
}
.fv_list_usernameModal {
  font-size: 3vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8850ab;
  margin-left: -12.5vw;
  flex: 1 1;
}
.fv_right_containerModal {
  margin-top: -34vw;
  width: 41%;
  position: absolute;
  padding: 0vh 1vw;
}
.fv_joined_textModal {
  text-align: left;
  position: relative;
  font-size: 3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.fv_left_tableModal {
  width: 29%;
  margin-left: 0px;
}
.fv_table_left_textModal {
  width: 4rem;
  font-size: 3vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #541957;
  margin-bottom: 4px;
}
.fv_table_right_textModal {
  /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size: 3vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f1252;
  margin-bottom: 4px;
}
.THANK-YOU-FORModal {
  width: 90%;
  position: absolute;
  padding: 0px 11px;
  margin-left: 0rem;
}
.sharingModal {
  width: 14vw;
  position: absolute;
  padding-bottom: 40vw;
  margin-left: 147px;
}
.textBAckModal {
  width: '19vw';
  display: flex;
  font-size: 5.7px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  font-family: Roboto;
  text-align: left;
  color: #000000;
}
.ForPreventModal {
  display: flex;
  font-size: 6px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* padding-top: 3vw; */
  padding: 43px 2px 1px 11px;
  font-family: Roboto;
  text-align: left;
  color: #543379;
  width: '19vw';
}
.bottomnumberModal {
  position: absolute;
  margin-top: -2vw;
  width: 56.9px;
  height: 5.9px;
  margin-left: 48vw;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.fv_center_details_textModal{
  /* padding: 1.5vw 3vw; */
 /* border: 1px solid red; */
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
 width: 100%;
 position: relative;
 /* margin-top: 10px; */
 text-align: center;
 /* margin-left: 25px; */
 font-size: 7px;
 margin-top: -28px;
 font-weight: 500;
 margin-bottom: 10px;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 color: #000000
 
}
}