.web_container{
    /* width: 1440px; */
	/* padding: 95px 118px 258px 40px;
	object-fit: contain; */
    margin-top: 80px;
    padding: 50px 25px;
    position: relative;
    z-index: 1;
}
.web_welcome_back {
    /* margin-bottom: -20px; */
    margin-bottom: -1.2vw;
    /* width: 20vw; */
    width: 15vw;
  
  }
  .web_top_username {
	text-transform: uppercase;
	font-family: 'Anton', sans-serif;
	font-size: 4vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: normal;
	color: #000000;

  }
  .web_middle_seconddiv {
    align-self: center;
    z-index: 0;
}
.cardwallet_back_notification {
    width: 23vw;
    height: 23vw;
    background-color: #f7f6f1;
}
.NotificationCard-BG {
    width: 23vw;
    height: 4vw;
}
.DisplayNotification_Card-BG, .NotificationCard-BG {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: #fff;
}
.cardwallet_onover_backdivMain {
    align-items: center;
    padding: .7vw .8vw;
    display: flex;
    justify-content: space-between;
}
.cardwaalet_img_backside {
    /* width: 2.5vw; */
    width: 30px;
}
.mainWrapper{
    top:0;
    position: relative;
    padding: 0 25px;
    /* margin-top: -28vh;
    margin-left: 1vw; */
}
.cardwallet_label_backside, .notification_label_backside, .notification_label_message {
    margin-left: .3vw;
    font-size: 1.2vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: .15px;
    color: #5f2161;
    font-family: Roboto;
    width: 100%;
    text-align: left;
}
.tempModalTxt {
    margin: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #333;
}
.notices{
    height: 18.5vw; overflow: auto; margin-top: 0px;
}
.inputBox {
    border-radius: 40px !important;
    padding-right: 40px !important;
    padding-left: 20px !important;
    width: 20vw !important;
    border-color: rgb(66, 0, 69) !important;
    border-width: revert !important;
    outline: none !important;
}
.cardwallet_onover_backdiv, .notification_details_mainDiv {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}
.Notification_category_img {
    width: auto;
}
.cardwallet_onover_backdiv {
    padding: .85vw .8vw;
}
.notification_category_label_details, .notification_category_Message {
    text-align: left;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: .15px;
}
.notification_category_Message {
    flex: 1 1;
    margin-left: 15px;
    font-size: .9vw;
    color: #4e4e4e;
}
.notification_category_label {
    text-align: left;
}
.notification_category_label, .viewAll {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: .15px;
    color: #8c827a;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.notification_details_Date {
    font-size: 12px;
    float: right;
}
.documents_infomiddiv {
    height: 15vw;
    background-color: rgba(234,232,219,.4);
    overflow: hidden;
    overflow-y: scroll;
    padding: 0vw 0;
}
.documentscardnew_welcome_div {
    padding: 1vw 1.5vw;
    font-size: 1vw;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: .15px;
    color: #4f4f4f;
    cursor: pointer;
}
.Card-BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 11vw;
    height: 11vw;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
    background-color: #fff;
    margin-bottom: .9vw;
    margin-right: .9vw;
    cursor: pointer;
    position: relative;
}
.maincard_image_icon {
    width: auto;
    /* height: 4vw; */
    margin-bottom: 50px;
}
.captiontextdiv {
    /* width: 9vw; */
    width: 118px;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-bottom: 10px;
}
.captiontextdiv, .DisplayNotification_captiontextdiv {
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: .15px;
    color: #5f2161;
    margin-top: 10px;
    font-family: Roboto;
    text-align: center;
    position: absolute;
    text-align: center;
    bottom: 0;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.webfooter {
    font-size: 12px;
    justify-content: space-between;
}
.tempModal, .webfooter {
    display: flex;
    align-items: center;
    padding: 2vw;
}
.documentscardnew_welcome_div:hover {
    background-color: #5f2161;
    color: #fff;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    /* .web_middle_seconddiv{
        display: none;
    } */
    .mainWrapper{
        top:0;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        /* margin-top: -44vh; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .web_welcome_back{
        width: 50%;
    }
    .cardwallet_onover_backdiv {
        padding: 2vw .8vw;
    }
    .web_top_username{
        font-size: 60px !important;
        line-height: 0.8 !important;
    }
    .cardwallet_back_notification{
        width: 90vw;
        height: 50vw;
        margin-bottom: 17px;
        background-color: #f7f6f1;
    }
    .documentsCard{
        height: 72vw;
    }
    .NotificationCard-BG {
        width: 90vw;
        height: 12vw;
    }
    .cardwallet_label_backside, .notification_label_backside, .notification_label_message {
        margin-left: 2.3vw;
        font-size: 5vw;
        font-weight: 500;
        text-align: left;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: .15px;
        color: #5f2161;
        font-family: Roboto;
    }
    .Card-BG {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 39vw;
        height: 39vw;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #e5e5e5, 0 1px 2px 0 #bdbdbd;
        background-color: #fff;
        margin-bottom: 2.9vw;
        margin-left: 0.9vw;
        cursor: pointer;
        position: relative;
    }
    .notification_category_Message {
        flex: 1 1;
        margin-left: 15px;
        font-size: 3.9vw;
        color: #4e4e4e;
    }
    .MobileCenter{
        justify-content: center !important;
        align-items: center !important;
    }
    .notices {
        height: 18.5vw;
        overflow: auto;
        margin: 4px;
        margin-top: 0px;
    }
    .inputBox {
        border-radius: 40px !important;
        padding-right: 40px !important;
        padding-left: 20px !important;
        width: 90% !important;
        border-color: rgb(66, 0, 69) !important;
        border-width: revert !important;
        outline: none !important;
    }
    .documents_infomiddiv {
        height: 60vw;
        background-color: rgba(234,232,219,.4);
        overflow: hidden;
        overflow-y: scroll;
        padding: .5vw 0;
    }
    .documentscardnew_welcome_div {
        padding: 3.5vw 3.5vw;
        font-size: 4vw;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: .15px;
        color: #4f4f4f;
        cursor: pointer;
    }
    .documentscardnew_welcome_div:hover {
        background-color: #5f2161;
        color: #fff;
        cursor: pointer;
    }
    .captiontextdiv, .DisplayNotification_captiontextdiv {
        font-size: 5.3vw;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: .15px;
        color: #5f2161;
        margin-top: 10px;
        font-family: Roboto;
        text-align: center;
        position: absolute;
        text-align: center;
        bottom: 0;
    }
    .cardwallet_onover_backdivMain {
        align-items: center;
        padding: 1.7vw 2.8vw 7.7px 7.5px;
        display: flex;
    }
    .web_container{
        /* width: 1440px; */
        /* padding: 25vh 118px 258px 40px;
        object-fit: contain; */
        margin-top: 60px;
        padding: 10px 20px;
    }
}