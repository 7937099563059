.chatbodyMobile {
	flex-grow: 1;
	padding: 20;
    overflow-y: scroll;
}
.chatbodyMobile .bubble {
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 9px;
	max-width: 330px;
}
.chatbodyMobile .intentbubble{
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 9px;
	max-width: 147px;
}
.chatbodyMobile .intentbubble p{
	margin-bottom: 0rem !important;
	font-size: 15px;
	text-align: left;
	line-height: 1.4;
	word-break: break-word; 
	font-family: 'Roboto';
	flex-grow: 0;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.chatbodyMobile .bubble p {
	margin-bottom: 0rem !important;
	font-size: 18px;
	text-align: left;
	line-height: 1.4;
	word-break: break-word; 
	font-family: 'Roboto';
	flex-grow: 0;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	
}
.chatbodyMobile .incoming {
	text-align: left;
}
.chatbodyMobile .incoming .bubble {
	color: #333333;
	background-color: rgba(223, 223, 223, 0.5) !important;
}
.chatbodyMobile .outgoing {
	text-align: right;
}
.chatbodyMobile .outgoing .bubble {
	color: #fdfdff;
	background-color: #2da5c8;
}
.intent{
	 background-color: '#54337A' !important;
	 color: #fff;
	 margin-right: 5px;
	 cursor:'pointer';

 }
.spinner {
	width: 70px;
	text-align: left;
  }
  
  .spinner > div {
	width: 10px;
	height: 10px;
	background-color: #333;
  
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
	0%, 80%, 100% { 
	  -webkit-transform: scale(0);
	  transform: scale(0);
	} 40% { 
	  -webkit-transform: scale(1.0);
	  transform: scale(1.0);
	}
  }

/* WebChatDesktop */
.web_Chat_container{
	width: 1440px;
	padding: 38px 118px 258px 40px;
	object-fit: contain;
}
.web_top_username {
	text-transform: uppercase;
	/* width: 35vw; */
	width: 100%;
	height: 70px;
	font-family: 'Anton', sans-serif;
	font-size: 4vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: normal;
	color: #000000;

  }
  .search-icon-mobile {
	position: relative;
	right: -4px;
	top: 5px;
	z-index: 8; /*this will keep the icon appearing all time, even when on input::focus*/
  }
  .form-control {
	padding-left: 1.75rem;
  }
  .chatHeader{
	width: 100vw;
	height: 65px;
	flex-grow: 0;
	margin: 0 0 0px;
	padding: 4.8px 17px 3.3px 9px;
	background-color: #533278;
	flex-direction: row;
  }
  .chatHeader p{
	width: 90vw;
    height: 17.9px;
    position: relative;
    flex-grow: 0;
    margin: -28px 35px 15.8px 38px;
    font-family: Tungsten;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.44px;
    text-align: center;
    color: #ffffff;
  }
  .Bottom-Controls-BG {
	width: 100vw;
	height: 12vh;
	flex-grow: 0;
	bottom: 0;
	padding: 20px 16px;
	overflow-y: hidden;
	position: fixed;
	z-index: 10;
	background-color: #e4e4e4;
  }
  .Bomba {
	width: 70.1px;
	height: 41.2px;
	flex-grow: 0;
	margin: 6.7px 5.9px 0 23px;
	background-color: #5f2161;
  }