.leftdrawer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  .mob_ld_menu_name_version {
    display: none;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(92, 86, 97); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .ld_user_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  }
  .ld_username {
    margin-left: 10px;
    /* width: 155px; */
    /* height: 22px; */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.1px;
    color: #000000;
    /* border: 1px solid blue; */
    text-transform: capitalize;
  }
  .ld_menu_container {
    display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border:1px solid blue; */
  width: 100%;
  /* margin-bottom: 5px; */
  cursor: pointer;
  padding: 10px 0px;
  padding-left: 20px;
  }
  .ld_menu_container:hover {
    background-color: #e5e7ea;
    border-right: 6px solid #543379;
    color: #420046;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ld_menu_container_selected {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border:1px solid red; */
    width: 100%;
    /* margin-bottom: 5px; */
    cursor: pointer;
    padding: 10px 0px;
    padding-left: 20px;
    background-color: #e5e7ea;
    border-right: 5px solid #543379;
  }
  
  .rightdrawer {
    display: flex;

    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;
    padding-top: 5vw;
    padding-left: 40px;
    padding-right: 40px;
    /* border: 1px solid red; */
  }
  
.rightdrawer_card {
  width: 100px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
  /* margin:10px; */
  margin-right: 30px;
  margin-top: 40px;
  cursor: pointer;
}

.rightdrawer_card_name {
  margin-top: 1vw;
  /* width: 119px; */
  /* height: 40px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.5px;
  color: #333333;

  /* display: flex;
    justify-content: center;
    align-items: center; */
  /* border: 1px solid red; */
}
.h_new {
  background-color: #543379;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 5;
}
.h_left_icon_div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.h_menuicon_draweropen {
  cursor: pointer;
}
.h_white_logo {
  margin-left: 10px;
  object-fit: contain;
}
.web_tooltiop_member_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.web_orange_tag_medical{
  width: 4vw;
  height: 14vw;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.18);
  background-color: #ff5417;
  top:80px;
  right:7px;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
  z-index: 1;
}
.web_patch_icon {
  object-fit: contain;
  margin-bottom: 30px;
}
.web_patch_text {
  margin-top: 1.2vw;
  width: 9vw;
  height: 2vw;
  transform: rotate(-90deg);
  font-family: 'Roboto';
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.web_tooltiop_member_container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.web_checkoutboldtext {
  font-size: 1.4vw;
  color: black;
  font-weight: 500;
  /* font-family:Roboto-Bold; */
}
.webhome_tooltip_container {
  background-image: url('./Images/callout_02.png');
  background-repeat: no-repeat;
  background-size: contain;
  position:fixed;
  top:83px;
  right:55px;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.5)) !important;
  z-index: 1;
  height: 15vw;
  width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5vw;
}
.web_tooltip_2nd_text {
  font-size: 1.2vw;
  height: 1.2vw;
  color: black;
  font-weight: 400;
  padding-left:26px;
  padding-right:14px;
  padding-bottom:19px;
  /* margin-left:4%; */
  /* font-family:Roboto-Bold; */
}
.yellow_popup_caption_button {
  position: absolute;
  border-radius: 20px;
  top:30%;
  left: 34%;
  right:26%;
  background-color: #eb5757;
  color: #fff;
  font-weight: 500;
  font-size:0.8rem;
  border:none;
  text-align: center;
}
/* .webhome_tooltip_container_medical{
  position:fixed;
  z-index: 1;
} */
.appsicon_cursor {
  cursor: pointer;
  margin-right: 0vh;
}
.notification_paper_shadow{
  z-index:5;
  max-height:400px;
  overflow:auto;
   border-radius: 10px;
   width:30%;
   -webkit-filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.5)) !important;
}
.notification_details_mainDiv{
  display: flex;
  align-items: center;
  padding:20px;
  border-bottom:solid 1px #eeeeee;
  cursor: pointer;
}
.Notification_category_img_details{
  width: 35px;
}
.mobile_notification_category_Message_details {
   margin-left: 2.3vw;
  width:100%;
  font-size: 0.9vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #4e4e4e;
}
.header_name {
  visibility: hidden;
  position: absolute;
}
.mob_ld_user_div_logo_img {
  display: none;
}
.notification_details_mainDiv_read{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  /* padding: 20px 2px; */
  padding:20px;
  border-bottom:solid 1px #eeeeee;
  cursor: pointer;
  background-color: #decdf1;
}
.notification_category_Message_details {
  width:100%;
  /* border: 1px solid red; */
  /* flex: 1; */
  /* margin: 0vw 1.0vw; */
   margin-left: 1.2vw;  /* width: 84px; */
  /* height: 18px; */
  /* font-family: Roboto; */
  /* font-size: 18px; */
  font-size: 0.9vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  color: #4e4e4e;
  padding-top: 2px;
}
.notification_details_seeAll{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  /* padding: 20px 2px; */
  padding:20px;
  border-bottom:solid 1px #eeeeee;
 
}
.notification_details_mainDiv:hover{
  background-color: #eeeeee; 
}
.notification_details_message{
    /* font-size: 1.1vw; */
    font-size: 14px;
    padding-bottom: 6px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #4e4e4e;
}
.notification_details_Date{
  /* margin-left:20px; */
  font-size: 12px;
  float: right;
  /* margin-left: 50px */
}
.notification_details_Time{
  margin-left:20px;
  font-size: 12px;
}

.webhome_tooltip_container_medical{
  background-repeat: no-repeat;
  background-size: contain;
  position:fixed;
  top:6.4%;
  left:88.4%;
  height: 15vw;
  width: 20vw;
  z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3vw;
}
.web_tooltiop_member_container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.web_orange_tag {
  /* width: 60px; */
  /* height: 200px; */
  width: 4vw;
  height: 13.5vw;
  z-index: 2;
  box-shadow: 72px -53px 21px 72px rgb(0 0 0 / 24%), 0 9px 18px 0 rgb(0 0 0 / 18%);
  background-color: #ff5417;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}
.web_yellow_tag{
  width: 4vw;
  height: 15vw;
  z-index: 1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24), 0 9px 18px 0 rgba(0, 0, 0, 0.18);
  background-color: #eb5757;
  margin-right: 0;
  display: flex;
  flex-grow: 0;
  margin-top: 13.5vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5vw 0px;
  cursor: pointer;
  position: fixed;
}
.web_patch_icon {
  object-fit: contain;
  margin-bottom: 18px;
}
.web_patch_icon_yellow {
  object-fit: contain;
  width: 32.5px;
  height: 20.8px;
  margin-bottom: 30px;
}
.web_patch_text {
  margin-top: 1.2vw;
  width: 11vw;
  height: 2vw;
  transform: rotate(-90deg);
  font-family: Roboto;
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.web_patch_text_yellow {
  margin-top: 2.8vw;
  width: 11vw;
  height: 2vw;
  transform: rotate(-90deg);
  font-family: Roboto;
  font-size: 1.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}
.menuICon{
  display: block !important;
}
.arrowICon{
  display:none !important;
}
.menuIConhidden{
  display:none !important;
}
@media only screen and (max-width: 768px) {
  .menuICon{
    display: none !important;
  }
  .menuIConhidden{
    display:block !important;
  }
  .arrowICon{
    display: block !important;
  }
  .notification_paper_shadow {
    overflow-x: hidden;
    z-index: 999;
    max-height: 320px;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0 5px 5px rgba(0,0,0,.5));
    width: 80%;
}
  .h_white_logo{
    display: none;
    position: absolute;
  }
  .header_name {
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.5px;
    color: #ffffff;
    position: relative;
    visibility: visible;
    margin-left: 20px;
  }
  .h_new {
    height: 60px;
    background-color: #543379;
    justify-content: space-between;
    padding: 0 20px;
    width: 100vw;
    position: fixed;
    z-index: 1;
    /* overflow: hidden; */
  }
  .mobile_full_size {
     top: 0;
     bottom: 0;
    /*left: 0;
    right: 0; */
     overflow: auto;
    /* background-color: #eae8db; */
    /* background-color:red; */
  }
  .webhome_tooltip_container_medical {
    display: none;
  }
  .leftdrawer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 2vw;
  }
  .ld_user_div{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .mob_ld_user_div_logo_img {
    display: block ;
    max-width: 180px;
    padding-left: 20px;
    height: 5rem;

  }
  .arrow_header {
    width: 100%;
    height: 56px;
    z-index: 1;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #543379;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
  }
  
  .arrow_headerdiv {
    flex: 1;
    margin: 0px 20px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.5px;
    color: #ffffff;

  }


   .mob_ld_menu_name_version {
     display: block;
    height: 24px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 500;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: .1px;
    color: rgba(0,0,0,.6);
}
}