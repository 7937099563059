@font-face {
  font-family: Tungsten;
  src: url('./Assets/Tungsten/font-face/561f38b1f4570de0fb8a39d691ab058c.ttf');
}
::-webkit-scrollbar-thumb {
  background: #D3D3D3 !important;
}
.passowrd_validation_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.announcementFooterButton {
  width: 150px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px !important;
  border: solid 2px #41b5c2 !important;
  background-color: #41b5c2 !important;
  color: #ffffff !important;
  font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
.disablecardPopup {
  width: 150px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px !important;
  border: solid 2px #eb5757 !important;
  background-color: #eb5757 !important;
  color: #ffffff !important;
  font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
.reset_note{
  margin-top: 2vw;
  font-family: Roboto;
  margin-bottom: 50px;
  font-size: 1.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}
.background-full-size {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  overflow-x: hidden;
  background-color: #eae8db;
  height: 100vh;
}
.form-control:focus {
	box-shadow: 0 0 0 0 !important;
} 
.loginform_containernew{
    width: 1017px;
    margin: auto;
    position: relative;
}
.login_image_container {
    /* border:1px solid red; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.login_main_image{
    width: 87%;
    margin-top: 14px;
  }
  .login_uhs_logo{
    position: absolute;
    right: 50px;
    top: 10px;
    height: 78px;
  }
  .login_new_error_text{
    color:red;
    border: 1px solid #e9716f;
    margin-top: 3vw;
    font-family: 'Oswald', sans-serif;
    font-size: 1.6vw;
    font-weight: 400;
    padding: 1.2vw 4vw;
}
.login_input_container_reset_div{
    margin-top: 44px;
    position: relative;
    /* border:1px solid red; */
    display: flex;
  }
  .login_input_container{
    margin-top: 44px;
      position: relative;
  }
  .login_input_username{
    outline-width: 0px;
    width: 361px;
    padding: 14px 30px;
    border-radius: 100px;
    border: solid 1px #697a86;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    font-stretch: normal;
    font-style: normal;
    color: #697a86;
    background-color: #fff;
    margin-right: 40px;
  }
.login_input_username:focus, .login_input_username:active{
    border: solid 1px #89969f;
    color: #89969f;
    background-color: #fff;
}
.p-viewer {
	z-index: 9999;
	position: absolute;
  font-size: 22px;
	top: 25%;
	right: 27%;
}
.p-viewer-firsttime {
  z-index: 9999;
  position: absolute;
  font-size: 28px;
  top: 60%;
  right: 27%;
}
.p-viewer-forgot-firsttime {
    z-index: 9999;
    position: absolute;
    font-size: 24px;
    top: 57%;
    right: 46%;
}
.p-viewer-forgot-secondtime{
  z-index: 9999;
  position: absolute;
  font-size: 24px;
  top: 57%;
  right: 6%;
}
.p-viewer-one-firsttime {
  z-index: 9999;
  position: absolute;
  font-size: 28px;
  top: 60%;
  right: 67%;
}
.login_button{
    width: 180px;
    padding: 14px 30px;
    border-radius: 100px;
    background-color: #e9716f;
    outline-width: 0px;
    border: none;
    color: #ffffff;
   font-family: 'Oswald', sans-serif;
   font-size: 24px;
  }
 .reset{
   width:auto !important;
  }
  .login_button:hover{
    border: none;
    outline: none;
  }
  .login_button:focus{
    border: none;
    outline: none;
  }
  .forgot_text-margin{
    margin-left: 448px;
    margin-top: 12px;
}
.loginmobile_forgot_text{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
    color: #e9716f;
    cursor: pointer;
    }
    .login_bottom_text{
        text-align: center;
        padding-top: 44px;
        /* padding-bottom: 120px; */
        padding-bottom:30px;
      }
      
      .login_bottom_subtext{
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        color: #000000;
      }
      .login_plese_text{
        font-weight: 500;
        color: #e9716f;
        cursor: pointer;
      }
    
      .login_new_error_text{
          color:red;
          border: 1px solid #e9716f;
          margin-top: 3vw;
          font-family: 'Oswald', sans-serif;
          font-size: 18px;
          font-weight: 400;
          padding: 1.2vw 4vw;
      }
    .forgot-input-con{
        margin-left: 300px
    }
    .loginmobile_header{
      display:none;
    }
    .crossButton{
      padding-left: 125px;
    }
    .pwd-validations .valid {
      color: #19bf00;
    }
    .pwd-validations .invalid {
      color: #df3312;
    }
    .pwd-validations .pwd-validation-txt {
      margin-left: 5px;
    }
    .center {
      margin: auto;
      width: 50%;
      padding: 10px;
    }

    /* ForgotPassword Screen */
    .checkregister_blue_text_container{
      /* position: absolute;
      top: 0px;
      left: 0px;
      display:block !important; */
      margin-right: 20px;
  }
  .checkregister_having_text{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.40;
      letter-spacing: normal;
      color: #5f2161;
      margin-bottom: 4px;
    
  }
  .checkregister_having_subtext{
    font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: normal;
      color: #000000;
  }
  /* .forgot-input{
    margin-left:283px !important;
  } */
  .code{
    width: 175px;
  }
  .changemypassword{
    margin-left:34%;
  }
  .checkregister_goto_subtext{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
      font-style: normal;
      line-height: 1.40;
      letter-spacing: normal;
      color: #000000;
      padding-top: 44px;
      /* padding-bottom: 120px; */
      padding-bottom: 30px;
      text-align: center;
  }
  .rege_goto{
    font-weight: 500;
    color: #e9716f;
    cursor: pointer;
   
    }
    @media only screen and (max-width: 600px) {
      .checkregister_blue_text_container{
        /* display: none !important; */
        position: relative;
        margin: 10px 0;
      }
      .changemypassword{
        margin-left:0%;
      }
      .forgot-input{
        margin-left:0 !important;
      }
      .p-viewer-one-firsttime {
        z-index: 9999;
        position: absolute;
        font-size: 28px;
        top: 34%;
        right: 8%;
    }
    .p-viewer-firsttime {
      z-index: 9999;
      position: absolute;
      font-size: 27px;
      top: 59%;
      right: 8%;
  }
      .loginmobile{
        margin: -7px;
        background-color: #ffffff;
      }
      .crossButton{
        padding-left: 125px;
      }
      .mobile-linput{
        margin-bottom: 15px;
    }
    .login_input_username{
      outline-width: 0px;
      padding: 16px 30px;
      padding-right:43px;
      width: 100%;
      margin-bottom: 16px;
      border-radius: 100px;
      border: solid 1px #89969f;
      font-family: 'Oswald', sans-serif;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      /* position: ƒ√; */
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #89969f;
      margin-right: 0;
    }
    .p-viewer {
      z-index: 9999;
      position: absolute;
      font-size: 20px;
      top: 44%;
      right: 8%;
  }
  .login_button {
    width: 81vw;
    padding: 14px 30px;
    border-radius: 100px;
    background-color: #e9716f;
    outline-width: 0px;
    border: none;
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
}
    .mobile-linput>input:first-child{
        margin-bottom: 30px;
    }
     .loginform_containernew{
      width: 81vw;
      padding: 0px 0px;
     }
     .login_image_container{
      text-align: center;
        margin-bottom: 30px;
    }
    .login_main_image {
      width: 101%;
      margin-top: 42px;
    }
    .login_uhs_logo {
      /* position: absolute;
      right: 108px; */
      width: 136px;
      /* top: -30px; */
      height: 74px;
  }
    .login_input_container{

    }
    .forgot_text-margin {
      margin-left: 59px;
      margin-top: 12px;
  }
      .loginmobile_header{
        height: 64px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
        background-color: #543379;
        padding:16px;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        position: sticky;
        top:0;
        z-index: 999;
        margin-bottom: 26px;
    
      }
      .loginmobile_header_text{
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.15px;
        padding: 0 100px;
        color: #ffffff;
      }
      .login_bottom_text {
        text-align: center;
        padding-top: 29px;
        margin-bottom: 82px;
        padding-bottom: 30px;
    }
    .p-viewer-forgot-firsttime {
      z-index: 9999;
      position: absolute;
      font-size: 24px;
      top: 40%;
      right: 9%;
  }
  .label-head {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #000;
    font-weight: 400 !important;
}
  .p-viewer-forgot-secondtime {
      z-index: 9999;
      position: absolute;
      font-size: 24px;
      top: 48%;
      right: 9%;
  }
  .reset_note {
    margin-top: 17px;
    font-family: Roboto;
    margin-bottom: 50px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
}
    }