.myneeds{
    border: 1px solid red;
    margin-top: 100px;
    width: 80%;
    /* display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; */
    /* float:center; */
    margin-left: auto;
    margin-right: auto;

    background-color: white;

}
.viewCardBtn{
  width: 204px;
  height: 43px;
  border-radius: 24px !important;
  border: solid 2px #41b5c2 !important;
  background-color: #ffffff !important;
  font-family: Tungsten;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.2px;
  text-align: center !important;
  color: #41b5c2 !important;
  padding:0px !important;
  box-shadow:none !important;
  float: right;
}
.Rectangle-271needs {
  width: 83vw;
  height: auto;
  background-color: #fcfcfc;
}

/* .progInfoContainer {
    padding: 0 !important;
    margin-top: 80px;
    position: relative;
  } */
  /* .commonWrap {
    padding-top: 26px;
  } */
  /* .commonWrap_MyNeeds {
     margin: 0 0 40px 0; 
  } */
  /* .commonWrap h2{
    margin: 0;
  } */
  /* .progInfoSection {
    padding: 20px;
    background: #ffffff;
  } */
  /* .progInfoTitle {
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #543379;
    text-transform: uppercase;
  } */
  .progInfoFooter {
    background: #F7F7F7;
    border-top: 1px solid #bdbdbd;
    padding: 20px;
  }
  .elevation {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.2), 0 1.3px 12.5px 0 rgba(0, 0, 0, 0.12), 0 5px 6.3px 0 rgba(0, 0, 0, 0.14);
    background-color: #41b5c2;
  }
  .footerTextLeft{
    /* font-weight: 500; */
    /* margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-weight: bold;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    
    color: #333333;
    padding: 2px 0; */
    position: absolute;
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #333333;
    /* padding: 2px 0; */
    padding-top: 17px;
  }
  .dpFlex {
    display: flex;
    flex-direction: row-reverse;
  }
  .footerText {
    padding: 9px 15px 0 0;
  }
  .footerText p{
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #333333;
    padding: 2px 0;
  }
  .footerText p:first-child{
    font-weight: bold;
  }
  .tablebackground{
      background-color: white;
      /* border: 1px solid red; */
      height: 350px;
      padding: 10px 30px;
      overflow: auto;
  }

  .headercellColor {
    /*color: #8c827a !important;*/
  }
  .sortheadercellColor {
    /* color: #8c827a !important; */
    background-color: #f9f9fa;
  }
  .rowcellcolor {
    font-size: 16px;
    /*color: #000000 !important;*/
    font-family: Roboto;
    font-weight: 400;
  }
  .sortrowcellcolor {
    font-size: 16px;
    /*color: #000000 !important;*/
    font-family: Roboto;
    font-weight: 400;
    background-color: #f9f9fa;
  }

  .inreview_button{
      background-color: #eb5757;
      color: #ffffff;
      /* width: 65px; */
      height: 16px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.4px;
      text-align: left;
      padding: 5px 10px;
      border-radius: 4px;
  }

  .final_button{
    background-color: #27ae60;
    color: #ffffff;
    /* width: 65px; */
    height: 16px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: left;
    padding: 5px 10px;
    border-radius: 4px;
}

.inner_viewbutn{
  padding: 5px;
  color: #fff;
  border-radius: 4px;
color: red;
}

.Audit{
  padding: 5px;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  background-color: #27ae60;
}
.Authorized{
  padding: 5px;
  color: #000;
  border-radius: 4px;
  background-color: #a9a9a9;
}
.Denied{
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  background-color: #eb5757;
}

.Reversed{
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  background-color: #ffa500;
}
.Pending{
  padding: 5px;
  color: #000;
  border-radius: 4px;
  background-color: #ffa500;
}
.ISSUE{
  padding: 5px;
  color: #000;
  border-radius: 4px;
  background-color: #ffa500;
}
.Logged{
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  background-color: #eb5757;
}



















/*--- Accordion My Needs CSS  --------------------------- */

.myneedcards {
  /*position: fixed;*/
  /*top: 0;*/
  /*left: 0;*/
  /*bottom: 0;*/
  /*right: 0;*/
  /*overflow: auto;*/
  /*background-color: #ffffff;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: space-between;*/
}
.myneedscard_header {
  width: 100%;
  height: 56px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #543379;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}
.myneedcard_headerdiv {
  /* border:1px solid white; */
  flex: 1;
  margin: 0px 20px;

  /* width: 220px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.itemWrap {
  margin-bottom: 10px !important;
}
.Expense-Number {
  /* width: 100px; */
  height: 13px;
  margin: 0 0 2.4px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
}
.Expense-Number2{
  /* width: 100px; */
  height: 13px;
  margin: 0 0 2.4px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  padding-left: 25px;
}
.Expense-Number22{
  /* width: 100px; */
  height: 13px;
  margin: 0 0 2.4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  padding-left: 60px;
}
.Expense-Number212{
  /* width: 100px; */
  height: 13px;
  margin: 0 0 2.4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  padding-left: 67px;
}
.Expense-Number21{
  /* width: 100px; */
  height: 13px;
  margin: 0 0 2.4px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  padding-left: 90px;
}
.Expense-Number221{
  /* width: 100px; */
  /*height: 13px;*/
  /* margin: 0 0 2.4px; */
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  
  overflow: hidden;
  white-space: break-spaces;
  /* text-overflow: ellipsis; */
  word-break: break-all;
  /* background-color: aqua; */
}
.Expense-Number11 {
  /* width: 100px; */
  height: 13px;
  margin: 0 0 2.4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
}
.Expense-Number211 {
  /* width: 110px; */
  /*height: 13px;*/
  /* margin: 0 0 2.4px; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  overflow: hidden;
  white-space: break-spaces;
  /* text-overflow: ellipsis; */
  word-break: break-all;
  /* background-color: aqua; */
}
.desktopTable{
  display: block !important;
}
.mobileTable{
  display: none !important;
}
@media only screen and (max-width: 460px) {
  .Back_page{
    display: none !important;
  }
  .commonWrap {
    padding-top: 5px;
}
  .desktopTable{
    display: none !important;
  }
  .mobileTable{
    display: block !important;
  }
}
@media only screen and (max-width: 325px) {
  .Expense-Number211 {
    /* width: 95px; */
    /*height: 13px;*/
    /* margin: 0 0 2.4px; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    text-align: left;
    color: #454d58;
    overflow: hidden;
    white-space: break-spaces;
    /* text-overflow: ellipsis; */
    word-break: break-all;
    /* background-color: aqua; */
  }
  }
  @media only screen and (max-width: 360px) {
    .Expense-Number211 {
      /* width: 95px; */
      /*height: 13px;*/
      /* margin: 0 0 2.4px; */
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.88px;
      text-align: left;
      color: #454d58;
      overflow: hidden;
      white-space: break-spaces;
      /* text-overflow: ellipsis; */
      word-break: break-all;
      /* background-color: aqua; */
    }
    }
.Expense-Card {
  width: 360px;
  height: 124.3px;
  margin-bottom: 127%;
  /* margin: 0 0 122.4px; */
  padding: 13.6px 7.7px 25.9px 0;
  box-shadow: inset 0 -1px 0 0 grey;
}
.row-distance{
  padding-left: 6.9%;
  /* padding-top: 15.3%;
  padding-right: 65.3%;
  padding-bottom:74.3% ; */
}
.col-distance-two{
  padding-left: 42.9%;
}
.status_button{
  color: #ffffff;
  /* width: 65px; */
  /*height: 16px;*/
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /*line-height: 1.33;*/
  letter-spacing: 0.4px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  /*margin-left: 25px;*/
}
.InReview {
  background: #eb5757;
}
.Final{
  background: #27ae60;
}
.final_button{
  background-color: #27ae60;
  color: #ffffff;
  /* width: 65px; */
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: left;
  padding: 5px 10px;
  border-radius: 4px;
}

.view_number {
  /*font-family: Roboto;*/
  /*font-size: 13px;*/
  /*font-weight: normal;*/
  /*font-stretch: normal;*/
  /*font-style: normal;*/
  /*letter-spacing: normal;*/
  /*text-align: left;*/
  color: #8c1342;
}
.panelSummary{
  flex-direction: row-reverse;
}

.panelSummary .MuiAccordionSummary-expandIcon {
  transform: rotate(-90deg) !important;
  margin-right: 5px;
  padding: 0px !important;
}
.panelSummary .MuiAccordionSummary-expandIcon.Mui-expanded{
  transform: rotate(0deg) !important;
  padding: 0px !important;
}
.panelSummary + .MuiCollapse-entered {
  overflow: auto;
  background: #f6f6f6;
}



.APPROVED{
  background-color: #27ae60;
}
.VOIDED{
  background-color: #a9a9a9;
}
.DECLINED{
  background-color: #eb5757;
}

.BATCHED{
  background-color: #ffa500;
}
.PENDING{
  background-color: #ffa500;
}
.ISSUE{
  background-color: #ffa500;
}

.Audit{
  background-color: #27ae60;
}
.Authorized{
  background-color: #a9a9a9;
}
.Denied{
  background-color: #eb5757;
}

.Reversed{
  background-color: #ffa500;
}
.Pending{
  background-color: #ffa500;
}
.ISSUE{
  background-color: #ffa500;
}